
import { Options, Vue } from 'vue-class-component'
import Loading from 'vue-loading-overlay' // Import Loading component

@Options({
  components: {
    Loading
  }
})
export default class StatsComponent extends Vue {
  devices: Array<{ title: string; current: number; total: number; health?: string; trend?: string; trendClass?: string; badgeClass?: string }> = [];
  isLoading = false; // Loading state

  // Fetch data from the API when the component is mounted
  async mounted () {
    this.isLoading = true // Set loading state to true while fetching data
    try {
      const response = await fetch('https://tri-m.app/ecabinet/stats.php')
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response.json()

      // Populate the devices array using the fetched data
      this.devices = [
        {
          title: '24-Hour Client Total',
          current: data.totalStationsLast24, // Example: active APs treated as active clients
          total: 0 // Total stations/clients
        },
        {
          title: 'Active Access Points with Connected Clients (%)',
          current: data.activeMacPercentage,
          total: 100, // Represented as a percentage out of 100
          trend: data.activeMacTrend, // Include the trend
          trendClass: this.getTrendClass(data.activeMacTrend)
        },
        {
          title: 'Max Clients per Access Points',
          current: data.maxStations,
          total: 0 // Total max stations is itself
        },
        {
          title: 'Active Access Points',
          current: 255, // Example: active APs treated as active clients
          total: 307 // Total stations/clients
        }
      ]
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      this.isLoading = false // Set loading state to false when done
    }
  }

  getTrendClass (trend: string): string {
    switch (trend) {
      case 'down':
        return 'text-success' // Green for decreasing usage
      case 'up':
        return 'text-danger' // Red for increasing usage
      case 'unchanged':
      default:
        return 'text-secondary' // Grey for stable usage
    }
  }

  getTrendWord (trend: string): string {
    switch (trend) {
      case 'down':
        return 'Decreasing' // Favorable trend
      case 'up':
        return 'Increasing' // Unfavorable trend
      case 'unchanged':
      default:
        return 'Stable' // Neutral trend
    }
  }
}
