import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69d002a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "graph-container" }
const _hoisted_2 = { class: "graph-controls" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_v_chart = _resolveComponent("v-chart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Loading, {
      active: _ctx.isLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
      "can-cancel": false,
      "is-full-page": false,
      loader:  'dots'
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeOptions, (option) => {
          return (_openBlock(), _createElementBlock("button", {
            key: option.value,
            onClick: ($event: any) => (_ctx.updateTimeRange(option.value)),
            class: _normalizeClass(['btn', _ctx.selectedTime === option.value ? 'btn-primary' : 'btn-outline-primary'])
          }, _toDisplayString(option.label), 11, _hoisted_3))
        }), 128))
      ]),
      (_ctx.chartOptions)
        ? (_openBlock(), _createBlock(_component_v_chart, {
            key: 0,
            option: _ctx.chartOptions,
            autoresize: "",
            style: {"height":"400px","width":"100%"}
          }, null, 8, ["option"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}