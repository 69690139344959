import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cf6a89c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav nav-pills nav-fill border mb-md-4" }
const _hoisted_2 = {
  key: 0,
  class: "nav-item border-end border-secondary border-opacity-10"
}
const _hoisted_3 = { class: "nav-item border-end border-secondary border-opacity-10" }
const _hoisted_4 = { class: "nav-item border-end border-secondary border-opacity-10" }
const _hoisted_5 = { class: "nav-item border-end border-secondary border-opacity-10" }
const _hoisted_6 = { class: "nav-item border-end border-secondary border-opacity-10" }
const _hoisted_7 = { class: "nav-item border-end border-secondary border-opacity-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_ctx.checkFirm)
      ? (_openBlock(), _createElementBlock("li", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: "/dashboard",
            class: _normalizeClass(["nav-link rounded-1", {active: _ctx.$route.path === '/dashboard'}])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Dashboard")
            ]),
            _: 1
          }, 8, ["class"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("li", _hoisted_3, [
      _createVNode(_component_router_link, {
        to: "/",
        class: _normalizeClass(["nav-link rounded-1", {active: _ctx.$route.path === '/'}])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t.map), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createVNode(_component_router_link, {
        to: "/entity",
        class: _normalizeClass(["nav-link rounded-1", {active: _ctx.$route.path === '/entity'}])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t.entities), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", _hoisted_5, [
      _createVNode(_component_router_link, {
        to: "/permission",
        class: _normalizeClass(["nav-link rounded-1", {active: _ctx.$route.path === '/permission'}])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t.permissions), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", _hoisted_6, [
      _createVNode(_component_router_link, {
        to: "/group",
        class: _normalizeClass(["nav-link rounded-1", {active: _ctx.$route.path === '/group'}])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t.groups), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", _hoisted_7, [
      _createVNode(_component_router_link, {
        to: "/division",
        class: _normalizeClass(["nav-link rounded-1", {active: _ctx.$route.path === '/division'}])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t.divisions), 1)
        ]),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}