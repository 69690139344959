
import { Options, Vue } from 'vue-class-component'
import {
  ObjectTemplate,
  ObjectType,
  StatTypeEnum,
  ObjectTypeEnum,
  RegionType,
  RegionEnum
} from '@cybertale/interface'
import http from '@/http-common'
import Search from '@/components/Search.vue'
import { Watch } from 'vue-property-decorator'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { $t } from '@geocms/localization'
@Options({
  computed: {
    $t () {
      return $t
    }
  },
  components: { Search, Datepicker },
  props: {
    object: ObjectTemplate
  }
})
export default class NavBarComponent extends Vue {
  statTypeEnum = StatTypeEnum
  objectTypeEnum = ObjectTypeEnum
  objectType = ObjectType
  regionType = RegionType
  regionEnum = RegionEnum
  object!: ObjectTemplate

  @Watch('$route')
  onDataChanged (value: any, oldValue: string) {
    const temp = document.getElementById('searchModalClose')
    if (temp !== null) {
      temp.click()
    }
  }

  async logout () : Promise<void> {
    await http.get(process.env.VUE_APP_BASE_URL + 'user/logout').then(response => {
      // success
      window.location.href = window.location.origin
    }, response => {
      // error
    })
  }

  get checkFirm () {
    return localStorage.getItem('firmName') === 'umag'
  }

  openReport (dates: Date[]) : void {
    window.open('https://tri-m.app/ormari/api/report.php?datefrom=' +
      dates[0].toISOString().split('T')[0] +
      '&dateto=' + dates[1].toISOString().split('T')[0])
  }
}
