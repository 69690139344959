import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsComponent = _resolveComponent("StatsComponent")!
  const _component_GraphComponent = _resolveComponent("GraphComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_StatsComponent),
    _createVNode(_component_GraphComponent)
  ], 64))
}